import { useEffect, useState } from "react";
import ReactECharts from 'echarts-for-react';
import { API_URL } from "./Config";

const AllStation = (props) => {
  const [lastWls, setLastWls] = useState([]);
  const [ratingCurves, setRatingCurves] = useState([]);
  const [qList, setQList] = useState([]);

  // Loading states
  const [lastWlsLoading, setLastWlsLoading] = useState(true);
  const [ratingCurvesLoading, setRatingCurvesLoading] = useState(true);

  useEffect(() => {
    // Fetch last water levels
    fetch(`${API_URL}wl/last`)
      .then(response => response.json())
      .then(data => {
        setLastWls(data);
        setQList(data.map(lastWl => wlConvertToQ(lastWl)));
        setLastWlsLoading(false); // Set loading state to false once data is loaded
      })
      .catch(error => {
        console.error('Error fetching last water levels:', error);
        setLastWlsLoading(false); // Set loading state to false in case of an error
      });

    // Fetch rating curves
    fetch(`${API_URL}wl/rating-curve`)
      .then(response => response.json())
      .then(data => {
        setRatingCurves(data);
        setRatingCurvesLoading(false); // Set loading state to false once data is loaded
      })
      .catch(error => {
        console.error('Error fetching rating curves:', error);
        setRatingCurvesLoading(false); // Set loading state to false in case of an error
      });

  }, []);

  // Check if both data sets are loaded
  const dataLoaded = !lastWlsLoading && !ratingCurvesLoading;

const findStationLastWl = (station_id) =>{
 const lastWl =  lastWls.find(station => station.id == station_id)
 return lastWl
}


const wlConvertToQ = (lastWl) => {
  if (ratingCurves && lastWl) {
     const ratingCurve =  ratingCurves.find(ratingCurve => ratingCurve.station_wl_id == lastWl.id)
     if (ratingCurve) {
      const q = (ratingCurve.x3*Math.pow(lastWl.value,3)) + (ratingCurve.x2*Math.pow(lastWl.value,2)) + (ratingCurve.x1*lastWl.value) + ratingCurve.x0
      const str = parseFloat(q.toString()).toFixed(2)
      return str
     }
     return null
  
  }
 
}

const station_ids = [292,321,320,300,301,329,322]

const getWLWaitZG = (lastWl) =>{
  if (lastWl) {
      const wlZG = lastWl.value + lastWl.zero_gauge
      const str = parseFloat(wlZG.toString()).toFixed(2)
      return str
  }
}

/* if (dataLoaded && !qList) {
  setQList(station_ids.map(id => wlConvertToQ(findStationLastWl(id))))
} */
/* const station_ids = [292,321,320,300,301,329,322] */
//const station_names = ['สถานีจิ่งหง','สถานีเชียงแสน','สถานีเชียงคาน','สถานีหนองคาย','สถานีนครพนม','สถานีมุกดาหาร','สถานีโขงเจียม']

/* const convertDateToTH =(date) => {
  const date_arr = date.split("-");
  const month_list = ["","มกราคม","กุมภาพันธ์","มีนาคม","เมษายน","พฤษภาคม","มิถุนายน","กรกฎาคม","สิงหาคม","กันยายน","ตุลาคม","พฤศจิกายน","ธันวาคม"]
  const year = parseInt(date_arr[0]) + 543
  const month = month_list[parseInt(date_arr[1])]
  return date_arr[2]+" "+month+" "+year
} */
      const option = {
          title: {
            text: 'กราฟแสดงความสัมพันธ์ระหว่างปริมาณน้ำและระดับน้ำของสถานี',
            textStyle: {
              fontSize: 18,
              fontFamily: 'Sarabun'
            },
            left: 'center'
          },
          tooltip: {
            trigger: 'axis',
          },
          /* legend: {
            data: ['ระดับตลิ่งและระดับท้องน้ำ', 'ระดับน้ำ','ปริมาณน้ำ'],
            itemStyle: {
              color: ["rgba(145, 45, 45, 1)","rgba(255, 45, 45, 1)"]
            },
            textStyle: {
              fontSize: 12,
              fontFamily: 'Sarabun'
            },
            left: 'left'
          }, */
          legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 10,
            top: 35,
            bottom: 20,
            data: ['ระดับตลิ่งและระดับท้องน้ำ', 'ระดับน้ำ','ปริมาณน้ำ'],
            textStyle: {
              fontSize: 12,
              fontFamily: 'Sarabun'
            },
          },

          grid: {
            left: '3%',
            right: '18%',
            bottom: '3%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: ['สถานีจิ่งหง','สถานีเชียงแสน','สถานีเชียงคาน','สถานีหนองคาย','สถานีนครพนม','สถานีมุกดาหาร','สถานีโขงเจียม'],
            }
          ], 
          yAxis: [{
            name: 'ม.รทก.',
            type: 'value',
            min: 80,
            max: 550,
          }, {
            type: 'value',
            name: 'ปริมาณน้ำ ลบ.ม./วิ',
          }],
          series: [
            {name: "ระดับน้ำ",
              smooth: true,
              type: "line",
              data: station_ids.map(id => getWLWaitZG(findStationLastWl(id))),
              symbol: 'triangle',
              symbolSize: 12,
              symbolRotate:60,
              symbolOffset: [0, -10]
            },
            {
              name: 'ระดับตลิ่งและระดับท้องน้ำ',
              type: 'line',
              smooth: true,
              areaStyle: {
                color: '#C2A887',
                opacity: 1,
              },
              lineStyle: {
                width: 1,
                color: '#C2A887'
              },
              showSymbol: false,
              emphasis: {
                focus: 'series'
              },
              data: [530,357.11,194.118,153.648,130.961,124.219,89.03]
            },
            {
              name: 'ปริมาณน้ำ',
              type: 'line',
              smooth: true,
              yAxisIndex: 1,
              data:station_ids.map(id => wlConvertToQ(findStationLastWl(id)))// [864.68, 1677.941, 4360.0781636,4311.126236768,6902.252294696799,6831.123905792,9344.5556456976]
            }
          ]
        };
      return (
        <>
        {dataLoaded ? (<ReactECharts option={option} notMerge={true} lazyUpdate={true}  style={{height: '400px', width: '100%'}}/>)
         : (<>
         </>)}
       </>)
      
}

export default AllStation;